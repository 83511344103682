<template>
    <div>
      <section class="home">
        <!--<div>       
          <FaixaPreBlack />
        </div>-->

        <div class="video-container">
          <div class="menu-desk">
            <TheMenu />
          </div>

             <!--<img src="@/assets/img/banner.png" class="desk imgdesk"/>
             <img src="@/assets/img/bannermob.png" class="mobile imgmobile"/>-->

             <!-- Vídeo Funcional -->
              <video v-if="usuarioLogado.perfil == 'funcional'" playsinline autoplay muted loop class="video videodesk">
                <source src="@/assets/videos/FUNCIONAL_WIDE.mp4" type="video/mp4" class="desk">
                <source src="@/assets/videos/FUNCIONAL-MOB.mp4" type="video/mp4" class="mobile">
              </video>
            
              <!-- Vídeo Outsider -->
              <video v-else-if="usuarioLogado.perfil == 'outsider'" playsinline autoplay muted loop class="video videodesk">
                <source src="@/assets/videos/OUTSIDER_WIDE.mp4" type="video/mp4" class="desk">
                <source src="@/assets/videos/OUTSIDER_RELLS.mp4" type="video/mp4" class="mobile">
              </video>
              <!-- Vídeo Style -->
              <video v-else-if="usuarioLogado.perfil == 'estiloso'" playsinline autoplay muted loop class="video videodesk">
                <source src="@/assets/videos/STYLE_WIDE.mp4" type="video/mp4" class="desk">
                <source src="@/assets/videos/STYLE_RELLS.mp4" type="video/mp4" class="mobile">
              </video>
              
              <!-- Vídeo Genérico -->
              <video v-else-if="usuarioLogado.perfil == 'geral'" playsinline autoplay muted loop class="video videodesk">
                <source src="@/assets/videos/WIDE_GERAL.mp4" type="video/mp4" class="desk">
                <source src="@/assets/videos/GERAL.mp4" type="video/mp4" class="mobile">
              </video>


            <div class="container">
              <div class="informacoes">
                <p>Olá,</p>
                <h1 >{{ primeiroNome }}!</h1>
                <router-link to="/personalizar-portal" class="btn-personalizar" style="text-decoration: none;" v-if="mostrarBotaoPersonalizar && !usuarioLogado.perfilDefinido">
                    PERSONALIZAR MEU PORTAL
                </router-link>
              </div>

             <div class="container">
                <div class="img-final desk">
                 <img
                    src="@/assets/img/apoio-banner.png"
                    alt="Logo Stanley"
                  />
                </div>
              </div>
            </div>
        </div>
      </section>
    </div>
</template>

<script>
import { api } from "@/services.js";
import TheMenu from "@/components/Base/TheMenu.vue";
export default {
  name: "BannerHome",
  components: {
    TheMenu,
  },
  data() {
    return {
      mostrarBotaoPersonalizar: false,
      loading: false,
      currentRoute: '' ,
      usuarioLogado: [],
    }
 },
 created() {
    this.currentRoute = this.$route.path; 
  },

  methods: {
      buscarUsuarios() {
        this.loading = true;
        api
          .get('/usuario/logged')
          .then((resp) => {
            this.usuarioLogado = resp.data;
            
            if(this.usuarioLogado.perfil == null) {
              this.usuarioLogado.perfil = 'geral';
            } 

            if(!this.usuarioLogado.perfilDefinido) {
              this.mostrarBotaoPersonalizar = true;
            }

          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
  },
   computed: {
    primeiroNome() {
      if (this.usuarioLogado.nome) {
        const nomeCompleto = this.usuarioLogado.nome;
        const primeiroNome = nomeCompleto.split(' ')[0];
        return primeiroNome;
      } else {
        return '';
      }
    }
  },
  mounted() {
    this.loading = true;
    this.buscarUsuarios();
  }
};
</script>

<style scoped>

  .home {
    height: 100vh;
  }

  .video {
    z-index: -9;
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .img-final {
    bottom: 0px;
    position: absolute;
    right: 70px;
  }

  .img-final img {
    width: 270px;
  }

  .btn-personalizar {
    transition: background-color 0.3s ease;
    background-color: #00abc8;
    color: white;
    font-size: 24px;
    box-shadow: 2px 3px 9px #00000033;
    cursor: pointer;
    padding: 20px 30px;
    letter-spacing: 0.36px;
    font-family: "klavika-web", sans-serif;
  }

  .btn-personalizar:hover {
    background-color: #00b6d5;
  }

  .informacoes {
    bottom: 70px;
    position: absolute !important;
  }

  .informacoes h1 {
    font-family: "Knockout";
    text-transform: uppercase;
    letter-spacing: 6px;
    color: #FFFFFF;
    text-shadow: 0px 0px 63px #00000073;
    font-size: 120px;
  }

  .informacoes p {
    font-size: 62px;
    color: #FFFFFF;
    font-family: "klavika-web", sans-serif;
    margin-bottom: -20px;;
  }

  .btn-mobile {
    padding: 5px !important;
    top: 20px;
    right: 15px;
    position: absolute;
    border: none;
    background-color: transparent;
  }

  .btn-mobile:hover,  
  .btn-mobile:focus,  
  .btn-mobile:active, .side-mob .btn:active {
    border: none !important;
    background-color: transparent !important;
  }

  .logo-mobile {
    width: 150px;
    top: 35px;
    left: 20px;
    position: absolute;
  }

  .menu-mobile {
    text-align: right;
    float: right;
    position: relative;
    right: 40px;
  }

  .nav-item a {
    color: #FFFFFF;
    font-size: 22px;
    font-family: "klavika light";
    font-weight: 300;
  }

  .nav-item a:hover {
    color: #D4D1C4;
  }

  .active a:after {
    content: '';
    display: inline-block;
    width: 8px; 
    height: 8px; 
    border-radius: 50%;
    background-color: #FF681E; 
    margin-left: 12px;
    position: relative;
    top: -3px;
  }

  .active a {
    color: #D4D1C4 !important;
  }

  .menu-desk {
    position: absolute;
    width: 100%;
  }

@media (min-width: 990px) { 
  
    video.desk {
      width: 100%;
      height: 100%;
      object-fit: cover; 
    }

    .mobile, .desktop {
      display: none !important;
    }

     .video-container {
        position: relative;
        height: 100vh;
    }

    .imgdesk {
      object-fit: cover;
      height: calc(100vh - 56px);
      width: 100%  !important;
    }
  }

  @media (max-width: 767px) { 

    .imgmobile {
      height: calc(100vh - 63px);
      object-fit: cover;
    }

     .video-container {
        position: relative;
        width: 100%;
        height: 100vh;
    }

    .menu-banner {
      position: absolute;
      width: 100%;
      padding: 0px 15px;
    }

    .video::-webkit-media-controls {
        display: none !important;
    }

    .video::-webkit-media-controls-enclosure {
        display: none !important;
    }

    .video::-webkit-media-controls-panel {
        display: none !important;
    }

    .video.videodesk {
      width: 100%;
      height: 100%;
      object-fit: cover; 
    }

    .desk {
      display: none;
    }

    .informacoes {
      left: 20px;
      bottom: 100px;
    }

    .informacoes h1 {
      font-size: 60px;
      letter-spacing: 2px;
    }

    .informacoes p {
      left: 20px;
      font-size: 33px;
      margin-bottom: -5px;
    }

    .btn-personalizar {
      font-size: 15px;
      height: 45px;
      padding: 10px 20px;
      margin-top: 0px;
    }
  }

</style>
