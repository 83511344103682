import Vue from 'vue'
import Vuex from 'vuex'
import {
  api
} from '@/services.js'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    login: false,
    rParam: null,
    userQueue: false,
    user: {
      "nome": "",
      "email": "",
      "cpf": "",
      "dataNascimento": "",
      "celular": "",
      "ativo": false,
      "hash": "",
      "id": "",
      "dataAtualizacao": "",
      "dataCadastro": "",
      "optInEmail": "",
      "optInSms": "",
      "optInWhatsapp": ""
    },
  },
  getters: {},
  mutations: {
    SET_R_PARAM(state, payload) {
      state.rParam = payload;
    },

    UPDATE_LOGIN(state, payload) {
      state.login = payload;
    },
    UPDATE_USER(state, payload) {
      window.localStorage.user = JSON.stringify(payload)
      state.user = payload;
    },

    UPDATE_QUEUE(state, payload){
      state.userQueue = payload
    }

  },
  actions: {

    changeQueue(context, payload){
      context.commit('UPDATE_QUEUE', payload)
    },

    logarUsuario(context, payload) {
      return api.post("/auth/login", payload)
        .then(response => {
          window.localStorage.token = response.data.token;
        })
    },

    getUsuario(context) {
      return api.get(`/usuario/logged`)
        .then(response => {
          context.commit('UPDATE_USER', response.data)
          context.commit('UPDATE_LOGIN', true)

        }).catch((error) => {
          if (error.response.status == 401) {
            context.dispatch('deslogarUsuario')
            this.$router.push('/auth/login')
          }
        })
    },

    deslogarUsuario(context) {
      context.commit('UPDATE_USER', {
        "nome": "",
        "email": "",
        "cpf": "",
        "dataNascimento": "",
        "celular": "",
        "ativo": false,
        "hash": "",
        "id": "",
        "dataAtualizacao": "",
        "dataCadastro": "",
        "optInEmail": "",
        "optInSms": "",
        "optInWhatsapp": ""
      })
      
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('user')
      context.commit('UPDATE_LOGIN', false)
    },

  },
  modules: {},
})