<template>
    <div>
        <section class="vemporai">
            <div class="container">
                <b-tabs content-class="mt-3 tabs-principais">
                    <b-tab title="CROSS BOTTLE" active>
                        <div class="conteudo-vemporai">
                            <h2 class="h-titulo">LANÇAMENTO <br><strong>CROSS BOTTLE</strong></h2>
                                <div>
                                    <b-tabs content-class="mt-3 tabs-internas">
                                        
                                        <b-tab title="SOBRE" active>
                                            <b-row>
                                                <b-col class="conteudo-tab" cols="12" lg="6"> 

                                                    <div class="box-tabs">
                                                        <p class="txt-tab">A Stanley lança a Cross Bottle, uma garrafa sofisticada que promete transformar a hidratação em um acessório de moda. Após o sucesso do Copo Quencher, a marca aposta em um design elegante e materiais premium para tornar a Cross Bottle prática e fácil de transportar, elevando o status da garrafa de água a um novo nível fashion e funcional.</p>
                                                    </div>
                                                </b-col>
                                                <b-col cols="12" lg="6">
                                                    <div class="imagem">
                                                        <img src="@/assets/img/crossbottle.png" alt="Imagem de lançamento" class="img-tab"/>
                                                    </div>                                      
                                                </b-col>
                                            </b-row>
                                        </b-tab>

                                        <b-tab title="TEMPERATURAS">
                                            <b-row>
                                                <b-col class="conteudo-tab" cols="12" lg="6"> 
                                                    
                                                    <div class="box-tabs-temperatura">
                                                        <img src="@/assets/img/Icons.png" alt="Imagem de temperatura" class="esp-mob"/>

                                                    </div>

                                                </b-col>
                                                <b-col cols="12" lg="6">
                                                    <div class="imagem">
                                                        <img src="@/assets/img/crossbottle.png" alt="Imagem de lançamento" class="img-tab"/>
                                                    </div> 
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                        
                                        <b-tab title="ESPECIFICAÇÕES">
                                            <b-row>
                                                <b-col class="conteudo-tab" cols="12" lg="6">
                                                    
                                                    <div class="box-tabs-especificacoes">
                                                    <img src="@/assets/img/Temp.png" alt="Imagem das especificações" class="temp"/>

                                                    </div>
                                                </b-col>
                                                <b-col cols="12" lg="6">
                                                    <div class="imagem">
                                                        <img src="@/assets/img/crossbottle.png" alt="Imagem de lançamento" class="img-tab"/>
                                                    </div> 
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                    </b-tabs>
                                </div>
                                                
                    </div>
                    </b-tab>
                </b-tabs>
                
                
            </div>
        </section>  
    </div>
</template>

<script>
export default {
  name: "VemPorAi",
  data() {
    return {
      activeTab: "Lançamentos" 
    };
  },
  /*computed: {
    sectionClass() {
      if (this.activeTab === "Lançamentos") {
        return "vemporai vemporai-lancamentos";
      } else if (this.activeTab === "Benefícios") {
        return "vemporai vemporai-beneficios";
      } else if (this.activeTab === "Produtos") {
        return "vemporai vemporai-produtos";
      }
      return "vemporai";
    }
  },
  methods: {
    beneficios() {
        this.activeTab = "Benefícios"
    },

    produtos() {
        this.activeTab = "Produtos"
    },

    lancamentos() {
        this.activeTab = "Lançamentos"
    }
  }*/
};
</script>

<style scoped>

.vemporai {
    background-color: #fff;
    padding: 60px 0px;
    position: relative;
    z-index: 999;
}

.vemporai-lancamentos {
    background-color: #D0EA8E;
}

.vemporai-beneficios {
    background-color: #E0BCB0;
}

.vemporai-produtos {
    background-color: #D4D1C4;
}

.h-titulo {
    letter-spacing: 3px;
    font-family: "klavika medium";
    color: #000000;
    line-height: 56px;
    font-size: 59px;
    margin-bottom: 10px;
}

.vemporai h2 strong {
    color: #708573;
}

.img-lancamento {
    width: 200px;
    margin-top: 20px;
}

.imagens-tab {
    margin-top: -200px;
    position: relative;
    gap: 20px;
}

.col-img {
    margin-top: 40px;
}

.titulo-tab {
    font-family: "klavika bold";
    letter-spacing: 2px;
    font-size: 50px;
    color: #000000;
}

.txt-tab {
    color: #000000;
    font-size: 18px;
    font-family: "klavika light";
    line-height: 23px;
    letter-spacing: 1.71px;
    width: 500px;
}

.conteudo-tab {
    position: relative;
}

.divisao {
    margin-top: 5px;
    gap: 10px;
    align-items: center;
}

.divisao img {
    width: 20px;
}

.titulo-beneficios {
    font-family: "klavika bold";
    font-size: 22px;
    color: #52575A;
    margin-bottom: 0px;
    margin-top: 2px;
}

.txt-beneficios {
    color: #52575A;
    font-family: "klavika light";
    font-size: 14px;
    margin-bottom: 0px;
    width: 500px;
}

.img-tab-ben {
    margin-top: -100px;
}

.imagem {
    margin-top: -200px;
    height: 550px;
    overflow: auto;
}

.box-tab-sobre {
    margin: 30px 0px;
}

.box-tab-sobre p {
    margin-bottom: 50px !important;
}

.box-tabs-temperatura {
    margin-top: 50px;
}

.temp {
    width: 250px;
}

@media (min-width: 991px) {

   /* .box-tabs {
        position: absolute;
        bottom: 0px;
    }*/

    .box-tabs {
        margin-top: 30px;
    }

    .box-tabs-especificacoes {
        position: absolute;
        bottom: 100px;
    }

    .box-tabs-temperatura {
        margin-top: 30px;
    }

    .mob {
        display: none;
    }
}

@media (max-width: 990px) {

    .temp {
       margin-bottom: 20px !important;
       margin-top: 20px  !important;
        margin: 0 auto;
        display: table;
    }

    .box-tabs-temperatura {
        margin-top: 10px;
    }

    .box-tab-sobre {
        margin: 20px 0px;
    }

    .box-tab-sobre p {
        margin-bottom: 20px !important;
    }

    .imagem-mob {
        overflow: scroll;
        width: 1500px;
    }

    .esp-mob {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .titulo-tab {
        font-size: 35px;
        margin-top: 20px;
    }

    .img-lancamento {
        width: 160px;
        height: 100%;
    }

    .desk {
        display: none !important;
    }
    .imagens-tab {
        display: block !important;
        margin-top: 0px;
        float: initial;
    }

    .h-titulo {
        line-height: 39px;
        font-size: 35px;
        text-align: center;
    }

    .txt-tab {
        width: 100%;
        position: relative;
        margin-top: 10px;
    }

    .txt-beneficios {
        width: 100%;
        font-size: 17px;
        line-height: 20px
    }

    .box-mobile {
        margin-top: 30px;
    }

    .conteudo-vemporai {
        padding: 0px 20px;
    }

    .img-tab-mob {
        width: 100%;
    }

    .divisao {
        margin-bottom: 5px;
    }

    .vemporai {
        padding: 40px 0px;
    }
    
    .imagem {
        margin-top: 0px !important;
        overflow: hidden;
        height: auto;
    }
}

</style>