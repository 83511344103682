import axios from 'axios';

import router from './router/index'
import store from './store/index'


const url = process.env.VUE_APP_API_HOST;

const axiosInstance = axios.create({
  baseURL: url,
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = window.localStorage.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers.ApiKey = 'Xi2NsD-fy05IuYC-sSVO0QQq8gp-B4vAEE9'
    return config
  },
  function (error) {
    return Promise.reject(error);
  }
)

axiosInstance.interceptors.response.use(function (response) {
  return response;
}, function (err) {

  // deslogar
  if (err.response.status == 401) {
    store.dispatch("deslogarUsuario");
    setTimeout(() => {
      router.push("/login");
    }, 1000);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }



  // const mensagem_erro_telegram = `
  // 🔥  Atenção\n\n 
  // 🖥️  Aplicação: SITE NEWBACON\n
  // 📅  Data: ${new Date().toLocaleString()}\n
  // 🌐   API: ${err.response.config.url}\n
  // 🚩  Erro: code - ${err.response.status} | erro - ${JSON.stringify(err.response.data)}\n
  // 📄  Body: ${btoa(err.config.data)}

  // `;

  // errorMonitoria(mensagem_erro_telegram)

  return Promise.reject(err);
});



export const api = {
  get(endpoint) {
    return axiosInstance.get(endpoint);
  },
  post(endpoint, body) {
    return axiosInstance.post(endpoint, body);
  },
  put(endpoint, body) {
    return axiosInstance.put(endpoint, body);
  },
  delete(endpoint) {
    return axiosInstance.delete(endpoint);
  },
  patch(endpoint, body) {
    return axiosInstance.patch(endpoint, body);
  },
};






export function errorMonitoria(mensagem) {
  // console.log(mensagem)
  fetch(`https://api.telegram.org/bot5307523807:AAHuxs-SIftLXbN_HQ_AqX5GsPur5TViBys/sendMessage?chat_id=-911193531&text=${encodeURIComponent(mensagem)}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  })
}

import * as signalR from '@microsoft/signalr';

// URL do SignalR, baseada em uma variável de ambiente definida no .env
const hubUrl = `${process.env.VUE_APP_API_HOST}/queueHub`.replace("/v1","");

// Função para iniciar a conexão com o SignalR
export const startSignalRConnection = () => {
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(hubUrl, {
      withCredentials: true, // Inclua as credenciais se necessário
    })
    .withAutomaticReconnect() // Reconectar automaticamente se a conexão for perdida
    .configureLogging(signalR.LogLevel.Information)
    .build();

  return connection.start()
    .then(() => {
      console.log("Conexão com SignalR estabelecida");
      return connection;
    })
    .catch(error => {
      console.error("Erro ao conectar com SignalR: ", error);
    });
};

// Função para se juntar à fila
export const joinQueue = (connection, userId) => {
  connection.invoke("TryLogin", userId)
    .catch(error => {
      console.error("Erro ao tentar login: ", error);
    });
};

// Função para obter os usuários ativos
export const getActiveUsers = (connection) => {
  return connection.invoke("GetActiveUsers")
    .then(users => {
      return users; // Retorna a lista de usuários ativos
    })
    .catch(error => {
      console.error("Erro ao buscar usuários ativos: ", error);
    });
};

// Função para deslogar
export const logoutUser = (connection, userId) => {
  return connection.invoke("Logout", userId)
    .catch(error => {
      console.error("Erro ao deslogar o usuário: ", error);
    });
};
