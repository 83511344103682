<template>
  <div>

    <div class="loading_global" v-if="loading">
        <span class="loader"></span>
    </div>

    <div v-else>
      <section v-if="!sucesso" class="container">
        <MenuMobile />
        <div class="container box-voltar">
          <div class="voltar">
                <button type="button" class="anterior" @click="voltar">
                    <img
                        src="@/assets/img/left.png"
                        alt="icone seta para esquerda"
                        class="seta"
                    />
                </button>
                <p><span>Meu Perfil </span> <br />Home</p>
          </div>
        </div>

        <div class="formulario">
            <div class="titulo-perfil">
                <h2>MEU <strong>PERFIL</strong></h2>
                <router-link v-if="!usuarioLogado.social" to="/alterar-senha" style="text-decoration: none;" class="alterar">
                    Alterar senha
                </router-link>
            </div>

            <form @submit.prevent="editarPerfil" v-if="!loading">
               <Notificacao
                  :infos="notificacao_infos"
                  v-if="notificacao_infos.mensagem"
                />
                  <div class="d-flex box-form-editar">
                    <div class="div-campos">
                       <label for="nome">Nome</label>
                        <input type="text" id="nome" name="nome" v-model="usuarioLogado.nome" required>  
                      </div>

                       <div class="div-campos">
                        <div class="d-flex justify-content-between">
                          <label for="email">Email</label>
                       <!-- <router-link to="/alterar-email" class="link-email">Alterar e-mail</router-link>-->
                        </div>
                        <input type="email" id="email" name="email" v-model="usuarioLogado.email" disabled required>
                    </div>
                   
                  </div>

                  <div class="d-flex box-form-editar">
                   
                    <div class="div-campos">
                      <label for="celular">Celular</label>
                       <the-mask
                          type="text"
                          id="celular"
                          name="celular"
                          v-model="usuarioLogado.celular"
                          :mask="'(##) #####-####'"
                          inputmode="numeric"
                          required
                          :masked="false"
                        />
                    </div>  

                    <div class="div-campos">
                      <label for="cpf">CPF</label>
                      <the-mask
                          type="text"
                          id="cpf"
                          name="cpf"
                          v-model="usuarioLogado.cpf"
                          :mask="'###.###.###-##'"
                          :masked="false"
                          inputmode="numeric"
                          required
                          disabled
                        />
                      </div>
                  </div>

                  <div class="d-flex box-form-editar">
                    
                    <div class="editar-campos espaco">
                       <label for="data">Data de nascimento:</label>
                        <input type="date" id="data" name="data"  v-model="formattedDataNascimento" required><br><br>
                    </div>

                    <div class="selecao-genero">
                        <label for="genero">Como você se identifica:</label>
                          <div class="generos">
                                  <div class="grupo1">
                                      <input type="radio" id="homem" name="genero" value="homem" v-model="usuarioLogado.genero" />
                                      <label for="homem">Homem</label>

                                      <input type="radio" id="mulher" name="genero" value="mulher" v-model="usuarioLogado.genero" />
                                      <label for="mulher">Mulher</label>

                                      <input type="radio" id="naobinario" name="genero" value="naobinario" v-model="usuarioLogado.genero"/>
                                      <label for="naobinario">Não binário</label>
                                      
                                  </div>

                                  <div class="grupo2">
                                      <input type="radio" id="outro" name="genero" value="outro" v-model="usuarioLogado.genero"/>
                                      <label for="outro">Outro</label>
                                      

                                      <input type="radio" id="nao" name="genero" value="naodizer" v-model="usuarioLogado.genero"/>
                                      <label for="naodizer">Prefiro não dizer</label>
                                  </div>
                          </div>
                      </div>
                  </div>

                  <div class="d-flex box-form-editar">

                      <div class="editar-campos espaco-cep">
                        <label for="cep">CEP</label>
                        <div class="d-flex box-cep">
                            <div>
                              <the-mask
                                type="text"
                                id="cep"
                                name="cep"
                                v-model="usuarioLogado.cep"
                                :mask="'#####-###'"
                                inputmode="numeric"
                                :masked="false"
                              />

                              <p class="msgCEP" v-if="errorCep">CEP não encontrado</p>
                            </div>

                            <button type="button" @click="buscarEnderecoPorCep(usuarioLogado.cep)" class="btn-buscar">BUSCAR</button>
                        </div>
                      </div>

                      <div class="editar-campos espaco-cep">
                        <label for="rua">Endereço</label>
                        <input type="text" id="rua" name="rua" v-model="usuarioLogado.endereco" :disabled="fieldsDisabled"><br><br>
                      </div>
                  </div>

                  <div class="d-flex box-form-editar">
                    
                    <div class="editar-campos">
                      <label for="numero">Número</label>
                      <input type="text" id="numero" name="numero" inputmode="numeric" v-model="usuarioLogado.numero"><br><br>
                    </div>

                    <div class="editar-campos">
                      <label for="Complemento">Complemento</label>
                      <input type="text" id="Complemento" name="Complemento" v-model="usuarioLogado.complemento"><br><br>
                    </div>
                  </div>

                  <div class="d-flex box-form-editar">
                    <div class="editar-campos">
                      <label for="cidade">Cidade</label>
                      <input type="text" id="cidade" name="cidade" v-model="usuarioLogado.cidade" :disabled="fieldsDisabled"><br><br>
                    </div>
                    <div class="editar-campos">
                      <label for="estado">Estado</label>
                      <input type="text" id="estado" name="estado" v-model="usuarioLogado.uf" :disabled="fieldsDisabled"><br><br>
                    </div>   
                  </div>

                  <div class="d-flex justify-content-center check-optin">
                    <div class="checkbox-form">
                      <input type="checkbox" id="termo" name="termo" v-model="usuarioLogado.optIn"/>
                      <label for="termo">Aceito receber email</label ><br />
                    </div>

                    <div class="checkbox-form">
                      <input type="checkbox" id="aceito" name="aceito" v-model="usuarioLogado.optInSms" />
                      <label for="aceito">Aceito receber whatsapp e SMS </label><br />
                    </div>
                  </div>
                  

                  <div class="box-atualizar">
                    <button type="submit" class="btn-avancar margin-btn">ATUALIZAR</button>
                  </div>
            </form>
            <!--<img src="@/assets/img/logo-preta.png" alt="Logo Stanley" class="logo-rodape" />-->   
           
        </div>
       
      </section>

      <section class="sucesso" v-else>
          <img
                  src="@/assets/img/logo_legionarios_branca.svg"
                  alt="Logo Stanley Legionários"
                  class="logo"
          />

          <div class="conteudo-sucesso" data-anima="bottom">
            <h1>PERFIL ATUALIZADO COM <strong>SUCESSO!</strong></h1>

            <router-link to="/home" style="text-decoration: none;" >
              <button type="button" class="btn-login">IR PARA A HOME</button>
            </router-link>
          </div>
      </section>
      
      <TheFooter />
    </div>

  </div>
  
</template>

<script>
import { api } from "@/services.js";
import TheFooter from "@/components/Base/TheFooter.vue";
import MenuMobile from "@/components/Base/MenuMobile.vue";
export default {
  name: "EditarPerfil",
  components: {
    MenuMobile,
    TheFooter
  },
  data() {
    return {
      fieldsDisabled: true,
      loading: false,
      errorCep: false,
      currentRoute: '' ,
      notificacao_infos: {
        variant: "info",
        mensagem: "",
        count_timer: 0,
      },
      usuarioLogado: {
        nome: "",
        cpf: "",
        email: "",
        dataNascimento: "",
        optIn: false, 
        optInSms: false,
        celular: "",
        cep: "",
        endereco: "",
        numero: "",
        complemento: "",
        cidade: "",
        uf: "",
        genero: "",
      },
      sucesso: false,
    }
 },
 created() {
    this.currentRoute = this.$route.path; 
  },

  methods: {

    async buscarEnderecoPorCep(cep) {
      this.loading = true;
            try {
                const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
                const data = await response.json();
                
                if (!data.erro) {
                    this.usuarioLogado.endereco = data.logradouro;
                    this.usuarioLogado.cidade = data.localidade;
                    this.usuarioLogado.uf = data.uf;
                    this.errorCep = false;

                    this.notificacao_infos = {
                      variant: "",
                      mensagem: "",
                      count_timer: 5,
                    };

                    
                } else {
                    this.errorCep = true;
                    this.loading = false;

                    this.notificacao_infos = {
                      variant: "error",
                      mensagem: "CEP inválido",
                      count_timer: 5,
                    };

                    window.scrollTo({
                      top: 0,
                      behavior: "smooth"
                    });
                    console.error('CEP não encontrado.');
                }

                this.loading = false;
            } catch (error) {
              this.loading = false;

              this.notificacao_infos = {
                variant: "error",
                mensagem: "CEP inválido",
                count_timer: 5,
              };

              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
              console.error('Erro ao buscar o CEP:', error);
            }
      },
      voltar() {
        this.$router.push("/home")
      }, 
      buscarUsuarios() {
        this.loading = true;
        api
          .get('/usuario/logged')
          .then((resp) => {
            this.usuarioLogado = resp.data;
             if (this.usuarioLogado.cep) {
                this.buscarEnderecoPorCep(this.usuarioLogado.cep);
              }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      },

       editarPerfil() {
        
        if(!this.errorCep) {
            this.loading = true;
        
            const data = {
                ...this.usuarioLogado,
                dataNascimento: new Date(this.usuarioLogado.dataNascimento).toISOString(),
            };

            api
                    .put("/usuario", data)
                        .then((response) => {
                        console.log(response.data);
                        this.sucesso = true;
                    })
                    .catch((error) => {
                        console.error(error);

                    })
                    .finally(() => {
                        this.loading = false;
                    });
            
          } else {
              this.notificacao_infos = {
                variant: "error",
                mensagem: "CEP inválido",
                count_timer: 5,
              };

              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
              
          }
        } 
  },
  computed: {
    formattedDataNascimento: {
          get() {
              if (this.usuarioLogado.dataNascimento) {
                  return this.usuarioLogado.dataNascimento.split('T')[0];
              } else {
                  return '';
              }
          },
          set(novaData) {
              this.usuarioLogado.dataNascimento = novaData ? `${novaData}T00:00:00` : null;
          }
      }
  },
   mounted() {
    this.buscarUsuarios();
  }
};
</script>

<style scoped>
  .box-voltar {
    padding: 0px !important;
  }

  .box-cep {
    justify-content: space-between;
    gap: 15px;
  }

  input:disabled {
    border-color: #bbbbbb;
    color: #818080;
    font-weight: 500;
    background-color: #eeeeee !important;
  }
  .logo-rodape {
        margin: 0 auto;
        padding-top: 80px;
  }

  .txthome {
    text-align: center;
  }

  .txtcadastrado {
      margin-bottom: 0px;
      font-family: "klavika-web", sans-serif;
      font-weight: 900;
      font-size: 25px;
      color: #000;
      text-align: center;
      text-transform: uppercase;
      margin-top: 100px;
  }

  .msgCEP {
    color: #d10000;
  }

  #cep {
    width: 230px !important;
  }

  .btn-buscar {
      transition: background-color 0.3s ease;
      background-color: #00abc8;
      color: white;
      font-size: 16px;
      box-shadow: 2px 3px 9px #00000033;
      cursor: pointer;
      padding: 10px 30px;
      border: none;
      letter-spacing: 0.36px;
      font-family: "klavika-web", sans-serif;
      height: 56px;
  }

  .btn-personalizar {
      margin-top: 50px !important;
      transition: background-color 0.3s ease;
      background-color: #00abc8;
      color: white;
      font-size: 20px;
      box-shadow: 2px 3px 9px #00000033;
      cursor: pointer;
      padding: 10px 50px;
      letter-spacing: 0.36px;
      font-family: "klavika-web", sans-serif;
      margin: 0 auto;
      display: table;
  }

  .btn-personalizar:hover, .btn-buscar:hover {
      background-color: #00b6d5;
  }

  .box-atualizar {
      margin: 0 auto;
      display: table;
      padding-top: 20px;
      padding-bottom: 30px;
  }

  .check-optin {
    margin-top: 50px;
    gap: 30px;
  }

  .check-optin label {
     font-family: "klavika light";
  }

  label, .link-email {
    font-family: "klavika bold";
    color: #232527;
    font-size: 14px;
  }

  .box-form-editar {
    justify-content: space-between;
  }

  .editar-campos {
    width: 47% !important;
  }
  
  .div-campos {
    width: 47% !important;
    margin-top: 20px;
  }
  .alterar {
    background-color: #52575A;
    text-decoration: none;
    color: #fff;
    padding: 10px 20px;
    margin-bottom: 10px;
  }

  .alterar:hover {
    background-color: #7A7A7A;
  }

  form {
    width: 800px;
    margin: 0 auto;
    padding-top: 30px;
  }
  .btn-mobile {
    padding: 5px !important;

    right: 15px;
    border: none;
    background-color: transparent;
  }

  .btn-mobile:hover,  
  .btn-mobile:focus,  
  .btn-mobile:active, .side-mob .btn:active {
    border: none !important;
    background-color: transparent !important;
  }

  .logo-mobile {
    width: 200px;
    top: 35px;
    left: 20px;
  }

  .menu-mobile {
    text-align: right;
    float: right;
    position: relative;
    right: 40px;
  }

  .nav-item a {
    color: #FFFFFF;
    font-size: 22px;
    font-family: "klavika light";
    font-weight: 300;
  }

  .nav-item a:hover {
    color: #D4D1C4;
  }

  .active a:after {
    content: '';
    display: inline-block;
    width: 8px; 
    height: 8px; 
    border-radius: 50%;
    background-color: #FF681E; 
    margin-left: 12px;
    position: relative;
    top: -3px;
  }

  .active a {
    color: #D4D1C4 !important;
  }

  .espaco, .espaco-cep {
    margin-top: 20px;
  }

  .titulo-perfil {
        display: flex;
        justify-content: space-between;
        width: 800px;
        margin: 0 auto;
        padding-top: 30px;
    }

    .titulo-perfil h2 {
        color: #232527;
        font-size: 38px;
    }

  .mob {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
  }

  .txtcadastro {
    color: #52575a;
    font-size: 16px;
    text-align: center;
    margin: 20px 0px;
  }

  .copyright {
    margin-top: 20px;
  }

  .view {
    margin-top: -21px;
  }

  .btn-avancar {
    transition: background-color 0.3s ease;
    background-color: #00abc8;
    color: white;
    font-size: 16px;
    border: none;
    box-shadow: 2px 3px 9px #00000033;
    cursor: pointer;
    width: 300px;
    height: 48px;
    margin-bottom: 40px;
  }

  .btn-avancar:hover {
    background-color: #00b6d5;
  }

  input {
    padding: 5px;
  }

  .data-nasc input,
  .data-nasc select {
    color: #52575a;
    font-size: 16px;
    font-family: "klavika-web-display", sans-serif;
    font-weight: 200;
  }

  .generos {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .generos label {
    color: #52575a;
    font-size: 14px;
    font-family: "klavika-web", sans-serif;
    font-weight: 400;
    margin-top: 16px;
  }


  .grupo1,
  .grupo2 {
    height: 40px;
    display: flex;
    gap: 12px !important;
    margin-right: 20px;
  }

  .generos input {
    width: 20px !important;
    margin-bottom: 0px !important;
  }

  .checkbox-form input {
    margin-right: 8px;
    margin-bottom: 0px;
    top: 3px;
    position: relative;
  }

  .checkbox-form {
    align-items: baseline;
  }

  .checkbox-form {
    display: flex;
    margin-bottom: 0px;
  }

  #nao {
    margin-left: 14px;
  }

  .link-termo {
    text-decoration: underline;
    color: #52575a;
  }

  #dataNascimento {
    width: 100% !important;
    font-weight: 600 !important;
    color: black !important;
    font-family: "klavika-web", sans-serif !important;
  }

  .data-nasc {
      width: 100%;
      margin-bottom: 20px;
  }

  .txt-termos {
      margin-top: 25px;
      color: #52575a !important;
      font-size: 12px;
      font-family: "klavika-web", sans-serif;
      font-weight: 400;
      
  }

  .txt-termos a {
    color: #52575a !important;
    text-decoration: underline;
  }

  .label-genero {
    margin-bottom: 0px;
  }

 .anterior {
        background-color: transparent;
        border: none;
    }

    .seta {
        width: 14px;
        margin-right: 15px;
        cursor: pointer;
    }

    .voltar {
        display: flex;
        margin: 15px 0px 30px 0px;
    }

    .voltar span {
        color: #52575a;
        font-weight: 200;
    }

    .voltar p {
        margin-bottom: 0px;
        line-height: 17px;
        color: #232527;
        font-size: 14px;
        font-family: "klavika-web", sans-serif;
        font-weight: 700;
    }
  
.sucesso {
    height: 100vh;
    background-color: #D4D1C4;
}

.sucesso h1 {
  color: #FFFFFF;
  font-size: 32px;
  font-family: "klavika-web-display", sans-serif;
  font-weight: 200;
  line-height: 35px;
  margin-bottom: 20px;
}

.sucesso strong {
  color: #FFFFFF;
  font-family: "klavika-web", sans-serif;
  font-weight: 700;
}

.sucesso p {
  color: #FFFFFF;
  font-size: 16px;
  font-family: "klavika-web", sans-serif;
  font-weight: 400;
}

.conteudo-sucesso {
    width: 350px;
    margin: 0 auto;
    padding-top: 120px;
}

.btn-login {
  transition: background-color 0.3s ease;
  background-color: #00ABC8;
  color: white;
  font-size: 16px;
  border: none;
  box-shadow: 2px 3px 9px #00000033;
  cursor: pointer;
  width: 350px;
  height: 48px;
  margin: 30px auto;
  display: block;
}

.btn-login:hover {
  background-color: #00b6d5;
}

.sucesso {
    background-image: url('@/assets/img/cadastro_prod_desk.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.selecao-genero label {
  margin-bottom: 0px;
  margin-top: 20px;
}

.selecao-genero .grupo1 {
  margin-top: -8px;
  margin-bottom: 0px;
}
@media (min-width: 991px) {
    .selecao-genero {
      width: 47%;
    } 
}

@media (max-width: 990px) {

  #cep {
    width: 100% !important;
  }

  .box-cep {
    margin-bottom: 20px;
    display: block !important;
  }

  .btn-buscar {
    width: 100%;
    margin-top: 20px;
    height: 52px;
  }

   .conteudo-sucesso {
      padding: 0px 10px;
      width: 100%;
      position: absolute;
      bottom: 0px;
  }

  .espaco-cep {
    margin-top: 0px;
  }

  .sucesso {
    background-image: url('@/assets/img/cadastro_prod_mob.jpg');

  }

  .div-campos, .box-form-editar, .check-optin {
    display: block !important;
  } 

  .div-campos, .editar-campos {
    width: 100% !important;
  }

  .titulo-perfil {
    align-items: center;
    width: 100%;
  }

  .titulo-perfil h2 {
      font-size: 25px;
  }

  .logo-mobile {
    width: 150px;
  }

  input[type="radio"]:checked:after  {
    top: 14px;
  }

  .txtcadastro {
    margin: 30px 0px;
  }

  .copyright {
    margin-bottom: 30px;
  }

  form {
    width: 100% !important;
    padding-top: 0px;
  }

  .box-atualizar {
    display: block;
  }

  .btn-avancar {
    width: 100%;
  }

  .logo-rodape {
    padding-top: 40px;
  }
}
</style>