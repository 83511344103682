<template>
  <div
    class="loading"
    :class="{ mini: mini, no_space: no_space, claro: claro }"
  >
    <div class="spinner"></div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    mini: {
      type: Boolean,
      default: false,
    },
    claro: {
      type: Boolean,
      default: false,
    },
    no_space: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.loading {
  text-align: center;
  padding: 50px 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: transparent;
  border: 2px solid #000;
  border-top-color: transparent;
  animation: spinner 0.3s linear infinite;
}
.claro .spinner {
  border: 2px solid #fff;
  border-top-color: transparent;
}
.loading.mini {
  margin-left: 20px;
}
.loading.no_space {
  padding: 0;
  margin: 0;
  justify-content: flex-start;
}
.loading.mini .spinner {
  width: 20px;
  height: 20px;
  border-width: 2px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>